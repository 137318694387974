<template>
  <div id="map">
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle"
            :center="center" :zoom="zoom"
            id="property-map" container="property-map">
      <MglMarker
          :coordinates="center"
      >
        <div slot="marker"><img :src="markerConfig.icon" /></div>
      </MglMarker>

      <MglMarker v-for="(value, name) in sales" :key="name"
                 v-bind:coordinates="[parseFloat(value.longitude), parseFloat(value.latitude)]"
      >
        <div slot="marker" class="marker marker-sales"><img :src="markerConfig.sales_icon" /></div>

        <MglPopup>
          <div class="text-center">
            <p><strong>{{value.street}}</strong></p>
            <p>$ {{value.lastSoldPrice |  formatNumber }}</p>
          </div>
        </MglPopup>

      </MglMarker>


      <MglGeojsonLayer
          :sourceId="geoJsonSource.data.id"
          :source="geoJsonSource"
          layerId="thisIsMySource"
          :layer="geoJsonLayer"
      />
    </MglMap>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglGeojsonLayer, MglMarker, MglPopup } from 'vue-mapbox';
import { mapState } from "vuex";

export default {
  components: {
    MglMap,
    MglGeojsonLayer,
    MglMarker,
    MglPopup,
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoiaW1zaXQiLCJhIjoiY2lyNnMwZWZ3MDBuZmdmbTl6b2NjdGRjaSJ9.OjOcVNGRMGpKNBiV1bZTRg',
      mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
      geoJsonSource: {
        type: 'geojson',
        data: {
          id: 'thisIsMySource',
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [102.0, 0.5],
              },
              properties: {
                id: 'value0',
                icon: {
                  iconUrl: 'https://i.picsum.photos/id/1040/10/10.jpg?hmac=l5Cp_plxzlfQobWWyd5uqBPiqjX1CApBpWP3w8DzYK0',
                  iconSize: [19, 19], // size of the icon
                  iconAnchor: [25, 25], // point of the icon which will correspond to marker's location
                  popupAnchor: [0, -25], // point from which the popup should open relative to the iconAnchor
                  className: 'custom-sales-marker',
                },
              },
            },
          ],
        },
      },
      geoJsonLayer: {
        type: 'circle',
        paint: {
          'circle-color': 'red',
        },
      },
    };
  },
  props: {
    mapConfig: {
      type: Object,
      default: {
        zoom: 19,
        mapTypeId: 'roadmap',
      },
    },
    markerConfig: Object,
  },
  computed: {
    ...mapState({
      sales: state => state.Results.sales,
    }),
    center: function () {
      return [this.mapConfig.center.lng || 49.38, this.mapConfig.center.lat || -66.94];
    },
    zoom: function () {
      return this.mapConfig.zoom
    },
    sales_icon: function () {
      return this.markerConfig.sales_icon
    },
    icon: function () {
      return this.markerConfig.icon
    },
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },
};
</script>

<style type="scss" lang="scss">
#map-container {
  position: relative;
  height: 560px;
  background-color: lightgray;

  .marker {
    background-image: url('../../../assets/images/pinpoint.png');
    background-size: cover;
    width: 36px;
    height: 58px;
    cursor: pointer;
  }

  .marker.marker-sales {
    background: none;
    background-image: url('../../../assets/images/comp_pointer.png');
    background-size: cover;
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  .map {
    width: 100%;
    height: 560px;
  }

  #property {
    position: absolute;
    bottom: 30px;
    background: rgba(51, 51, 51, 0.6);
    width: 100%;

    p {
      margin: 0;
      padding: 10px;
      font-size: 30px;
      font-weight: 300;
      color: #fff;
      line-height: 1em;

      span {
        font-size: 16px;
      }
    }
  }
}

.marker {
  background-image: url('../../../assets/images/pinpoint.png');
  background-size: cover;
  width: 36px;
  height: 58px;
  cursor: pointer;
}

.marker.marker-sales {
  background: none;
  background-image: url('../../../assets/images/comp_pointer.png');
  background-size: cover;
  width: 19px;
  height: 19px;
  cursor: pointer;
}

#property-map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
