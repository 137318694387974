<script>
import { mapState } from "vuex";
import DbcApi from "@/api/dbc";
import PropertyApi from "@/api/property";

import Footer from "@/components/LandingResult/components/Footer";
import pin from "@/assets/images/pinpoint.png";
import sales_pin from "@/assets/images/comp_pointer.png";

export default {
  name: "BaseLandingResult",
  components: { Footer },
  props: ["isResultPage"],
  data() {
    return {
      contact: null,
      agent: null,
      listings: null,
      property: null
    }
  },
  computed: {
    ...mapState({
      valuations: state => state.Results.valuations
    }),
    allDataLoaded: function () {
      return this.contact !== null &&
             this.agent !== null &&
             this.listings !== null &&
             this.property !== null;
    },
    propertySpecs: function() {
      return `
        ${this.property.bedrooms ? this.property.bedrooms + " bd" : ""}
        ${this.property.bathrooms ? " &bullet; " + this.property.bathrooms + " ba" : ""}
        ${this.property.squareFootage ? " &bullet; " + this.property.squareFootage + " sq ft" : ""}
      `;
    },
    location: function() {
      return { lat: Number(this.property.latitude || 0), lng: Number(this.property.longitude || 0) };
    },
    mapConfig: function() {
      return {
        center: this.location,
        zoom: 15,
        zoomControl: false,
        streetViewControl: false
      };
    },
    markerConfig: function() {
      return {
        position: this.location,
        icon: pin,
        sales_icon: sales_pin,
        title: this.contact ? this.contact.street_address_1 : "",
        other_sales_list: this.sales || []
      };
    }
  },
  methods: {
    handleNewAddress: function (address) {
      let split = address.split(",");
      let street = split[0];
      let city = split[1];
      let stateAndZip = split[2].trim().split(" ");
      let state = stateAndZip[0];
      let zip = stateAndZip[1];

      PropertyApi.getPropertyDetails(street, city, state, zip)
          .then((result) => {
            this.property = result;
          })
          .catch((error) => {
            console.error(error);
          })

      this.contact.street_address_1 = street;
      this.contact.city = city;
      this.contact.state = state;
      this.contact.zip = zip;

      this.$store.dispatch("Results/getValuations", this.contact);
    }
  },
  beforeMount() {
    const contactId = this.$route.params.contact_id;
    const campaignId = this.$route.params.campaign_id || 0;

    DbcApi.getContactDetails(contactId).then((data) => {
      this.contact = data.contact;
      this.agent = data.agent;
      this.listings = data.listings;
      this.property = data.property;

      this.$store.dispatch("Tracking/addPageView", {
        agentId: this.contact.agent_id,
        contactId: this.contact.id,
        campaignId: campaignId,
        contact: this.contact
      });

      if (this.isResultPage) {
        this.contact.geocode = {
          lat: this.property.latitude,
          lng: this.property.longitude
        }
        this.$store.dispatch("Results/getValuations", this.contact).then(() => {
          this.$store.dispatch("Results/getOtherSales", {
            address: `${this.contact.street_address_1}`,
            state: this.contact.state,
            zipcode: this.contact.zip.substring(0, 5),
            city: this.contact.city,
            origLat: this.property.latitude,
            origLon: this.property.longitude,
            vals: this.valuations
          });
        });
      }
    });

  }
}
</script>

<template>
  <div v-if="allDataLoaded">
    <div
      class="greeting-container hv-landing-properties "
    >
      <slot
          name="header"
          :agent="agent"
          :contact="contact"
      ></slot>

      <slot
          name="estimations"
          :contact="contact"
          :agent="agent"
      ></slot>

      <slot
          name="property"
          :mapConfig="mapConfig"
          :markerConfig="markerConfig"
          :contact="contact"
          :agent="agent"
          :propertySpecs="propertySpecs"
          :valuations="valuations"
          :handleNewAddress="handleNewAddress"
      ></slot>

      <slot
          name="featured"
          :listings="listings"
      ></slot>
    </div>

    <slot name="footer">
      <div class="row bg-dark mt-20">
        <div class="container text-light p-3">
          <div class="row">
            <div class="col col-12">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<style type="scss" lang="scss">
.welcome-text {
  color: white;
}

.text-white {
  color: white;
}

.p-5 {
  padding: 5rem;
}

.mt-20 {
  margin-top: 20px;
}

.float-left {
  float: left;
}

#property {
  position: absolute;
  bottom: 30px;
  background: rgba(51, 51, 51, 0.6);
  width: 100%;

  p {
    margin: 0;
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    line-height: 1em;

    span {
      font-size: 20px;
    }
  }
}
</style>
