<template>
  <div class="wrapper-spinner">
    <div class="spinner-border" role="status">
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoaderCentered",
  props: {},
};
</script>
<style scoped lang="scss">
.wrapper-spinner {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}
.spinner {
  width: 4rem;
  height: 4rem;
  font-weight: bold;
}
</style>
