<template>
  <fragment>
    <transition name="fade">
      <app-loader-centered v-show="!imageLoaded && !sceleton" />
    </transition>
    <fragment v-show="imageLoaded">
    <transition name="fade">
      <div v-show="imageLoadedLoaderRemoved && !sceleton" class="thumbnail">
        <img
          :src="imageUrl || $options.defThumbUrl"
          alt="property image"
          @load="onImageLoaded"
          class="thumb-image"
        />
        <div class="caption">
          <h3>{{ price }} {{ status }}</h3>
          <p>
            <small>{{ address }} </small><br />
            <small>{{ description }}</small
            ><br />
          </p>
        </div>
      </div>
    </transition>
    </fragment>
    <transition name="fade">
      <div v-show="sceleton" class="thumbnail sceleton">
        <div class="image-placeholder"></div>
        <div class="caption">
          <h3><div class="filler-price"></div></h3>
          <div class="span-holder">
            <small><div class="filler-address"></div> </small><br />
            <small><div class="filler-description"></div></small><br />
          </div>
        </div>
      </div>
    </transition>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import AppLoaderCentered from "./AppLoaderCentered.vue";

export default {
  name: "AppFeaturedPropertyImageCard",
  components: {
    Fragment,
    AppLoaderCentered,
  },
  defThumbUrl:
    process.env.VUE_APP_GALLERY_THUMB_URL ||
    "https://dmg-widget.s3-us-west-2.amazonaws.com/gallery/photo-placeholder-icon-6.jpg",
  props: {
    property: {
      type: Object,
      default: function() {
        return {
          address: "address",
          city: "city",
          state: "state",
          zip: "zip",
          beds: "-",
          baths: "-",
          sqft: "-",
          price: "price",
        };
      },
    },
    sceleton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: "",
      description: "",
      address: "",
      price: "",
      status: "",
      imageLoaded: false,
      imageLoadedLoaderRemoved: false,
    };
  },
  mounted() {
    this.imageUrl = this.property.photo_url;
    this.price = `${this.property.price !== "price" ? "$" : ""} ${
      this.property.price
    }`;
    this.status = this.defineStatus();
    this.description = `${this.property.beds !== "" ? this.property.beds : '-'} bd • ${this.property.baths !== "" ? this.property.baths : '-'} ba • ${this.property.sqft !== "" ? this
    .property.sqft : '-'} sqft`;
    this.address = `${this.property.address}, ${this.property.city}, ${this.property.state}, ${this.property.zip}`;
  },
  methods: {
    defineStatus() {
      if (this.property.status === "S") {
        return "(SOLD)";
      } else if (this.property.status === "P") {
        return "(PENDING)";
      } else if (this.property.status === "A") {
        return "(ACTIVE)";
      } else {
        return "(status)";
      }
    },
    onImageLoaded() {
      this.imageLoaded = true;
      setTimeout(() => {
        this.imageLoadedLoaderRemoved = true;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.thumbnail {
  border: 1px solid #e7e7e7;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  height: 280px;
  padding: 0 0 11.5px;
  cursor: not-allowed;

  .thumb-image {
    width: 100%;
    object-fit: cover;
    height: 60%;
  }

  .caption {
    padding: 11.5px 11.5px 0 11.5px;

    h3 {
      font-size: 1.125rem;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }
}
.sceleton {
  opacity: 0.4;
}

.filler-price {
  background: lightgrey;
  border-radius: 5px;
  min-height: 12px;
  width: 60%;
  margin-top: 7px;
}
.filler-address {
  background: lightgrey;
  border-radius: 5px;
  min-height: 10px;
  width: 80%;
  margin-top: 15px;
}

.filler-description {
  background: lightgrey;
  border-radius: 5px;
  min-height: 10px;
  width: 70%;
}

.image-placeholder {
  background: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  color: white;
  font-weight: bold
}
.span-holder{
  height: 30px;
}

@media screen and (max-width: 767px) {
  .thumbnail {
    margin-top: 10px;
  }
}

</style>
