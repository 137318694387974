<template>
  <footer class="bg-dark text-center text-white p-2">
      <p class="p-0 m-0">
        Listing Results: &copy; Deluxe Branded Marketing, 2020
        - {{ new Date().getFullYear() }}.
        Use is subject to <router-link :to="{name:'Tos'}" class="text-white bold nav-underline">Terms of Use</router-link>
      </p>

      <small>
        If your property is currently listed with a Realtor, please disregard this
        notice. It is not our intention to solicit the offerings of other Brokers.
      </small>

      <br />

      <small>
        v{{VERSION}}
      </small>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed:{
    VERSION: () => '0.0.1',
  }
}
</script>

<style scoped>

</style>