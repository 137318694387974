<template>
  <div class="row items-holder">
    <fragment v-if="listings.length">
      <div
        class="col-xs-12 col-md-3 featured-item"
        v-for="(listing, index) in listings"
        :key="listing.id"
      >
        <app-featured-property-image-card
          v-if="index < numberOfItems"
          :property="listing"
        />
      </div>
    </fragment>
  </div>
</template>

<script>
import AppFeaturedPropertyImageCard from "./AppFeaturedPropertyImageCard.vue";
import { Fragment } from "vue-fragment";

import {NUMBER_OF_FEATURED_PROPERTIES_CARDS} from "@/config";

export default {
  name: "FeaturedBlock",
  components: {
    AppFeaturedPropertyImageCard,
    Fragment,
  },
  props: ["listings"],
  data() {
    return {
      numberOfItems: NUMBER_OF_FEATURED_PROPERTIES_CARDS,
    };
  }
};
</script>

<style scoped lang="scss">
.featured-item {
  .thumbnail {
    border: 1px solid #e7e7e7;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    background: #fff;
    height: 280px;
    padding: 0 0 11.5px;

    .caption {
      padding: 11.5px 11.5px 0 11.5px;

      h3 {
        font-size: 1.125rem;
        font-weight: 500;
      }

      p {
        font-size: 13px;
        margin: 0;
      }
    }
  }
}

.featured-item img {
  height: 150px;
  width: 100%;
  display: block;
}

@media screen and (min-width: 767px) {
  .items-holder {
    max-height: 280px;
  }
}
</style>
